<template>
  <span v-if="item.use_hr == 'Yes'">
    <v-card-text
      class="py-0 px-0 pl-2"
      v-if="item.use_hr == 'Yes' && item.avg_hr > 0"
    >
      <v-icon color="paper">
        mdi-heart-pulse
      </v-icon>
      <span title="Peak HR" :class="textSizeSmall + ' paper--text text-right'">
        Peak: {{ item.peak_hr }} BPM
      </span>
      <v-spacer />
      <v-icon color="paper">
        mdi-heart-half-full
      </v-icon>
      <span title="Avg. HR" :class="textSizeSmall + ' paper--text'">
        Avg.: {{ item.avg_hr }} BPM
      </span>
      <v-spacer />
    </v-card-text>
    <v-card-actions class="py-0 pr-0" v-if="item.effort > 0">
      <v-icon color="paper">
        mdi-heart-flash
      </v-icon>
      <v-progress-linear
        rounded
        class="ml-2"
        height="30"
        background-color="charcoal lighten-1"
        :color="effortColor(item.effort)"
        :value="Math.round(item.effort)"
      >
        <template class="text-left" v-slot:default="{ value }">
          <span class="paper--text py-2"> Effort {{ value }}% </span>
        </template>
      </v-progress-linear>
    </v-card-actions>
  </span>
</template>
<script>
import util from '@/mixins/util.js'
export default {
  name: 'MyZoneDetails',
  mixins: [util],
  data: () => ({}),
  props: {
    item: {
      type: Object
    },
    weight: {
      type: String,
      default: ' font-weight-bold'
    }
  }
}
</script>
